import { EMPLOYEE_URL_CONSTANTS } from "../../APIVar/EmployeeUrlConstant";
import { URL_CONSTANTS } from "../../APIVar/URLConstants";
import { GetAPI, PostAPI, DeleteAPI, PatchAPI } from "../API";

const create_employee = (
  dto: any,
  onSuccess: any,
  config: any,
  onError: any
) => {
  PostAPI.call(URL_CONSTANTS.create_employee, dto, onSuccess, {}, onError);
};
// get client list
const get_client_list = (
  user: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_client_list(user), onSuccess, onError);
};
// get insurer list
const get_insurer_list = (
  user: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_insurer_list(user), onSuccess, onError);
};

// delete employee
const delete_employee = (
  dto: any,
  onSuccess: (data: any) => void,
  config: any,
  onError: (err: any) => void
) => {
  DeleteAPI.call(URL_CONSTANTS.delete_employee, dto, onSuccess, {}, onError);
};

// update the detail global
const update_detail_global_employee = (
  dto: any,
  onSuccess: any,
  onError: any,
  id: string
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.update_employee_detail_global}${id}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

// get client location list
const get_location_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_client_location_list}${id}`,
    onSuccess,
    onError
  );
};
// get header tags
const get_header_tag = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.get_header_tag, onSuccess, onError);
};
// get table data
const get_table_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  limit: number,
  skip: number,
  starDate: any,
  endDate: any,
  searchValue: string
) => {
  GetAPI.call(
    URL_CONSTANTS.get_table_data_employee(
      limit,
      skip,
      starDate,
      endDate,
      searchValue
    ),
    onSuccess,
    onError
  );
};

// get table data
const memberlistAccordingPolicy = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string,
  usertypeinfo: string,
  limit:number ,
  skip :number,

) => {
  GetAPI.call(
    URL_CONSTANTS.memberlistAccordingPolicy(id, usertypeinfo,limit,skip),
    onSuccess,
    onError
  );
};

const get_dropdown_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  clientId: any
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_employees_by_clientId}clientId=${clientId}`,
    onSuccess,
    onError
  );
};

// get employe detail view information
const employee_detail_view_layout = (
  id: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_employee_detail_layout}${id}`,
    onSuccess,
    onError
  );
};



// individual edit employee detail
const update_detail_view_individual_edit_employee = (
  dto: any,
  onSuccess: any,
  onError: any,
  id: string,
  usertypeinfo: string
) => {
  PatchAPI.call(
    `${URL_CONSTANTS.individual_edit_employee_detail(id, usertypeinfo)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

// get status  data for detail page
const get_status_data = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  id: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.dynamic_status_list}?id=${id}`,
    onSuccess,
    onError
  );
};


// update status
const update_status = (dto: any, onSuccess: any, onError: any, id: string, type: string, usertypeinfo: string) => {
  PatchAPI.call(
    `${URL_CONSTANTS.employee_status_change(id, type, usertypeinfo)}`,
    dto,
    onSuccess,
    {},
    onError
  );
};

const get_dep_designation_salary = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  user: string,
  clientId: string,
  type: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_dep_designation_salary(user)}?clientId=${clientId}&type=${type}`,
    onSuccess,
    {},
    onError
  );
};
const massModification = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(URL_CONSTANTS.massmodificationemployee, onSuccess, onError);
};

const updateMassmodification = (
  dto: any,
  onSuccess: (data: any) => void,
  { },
  onError: (err: any) => void
) => {
  PatchAPI.call(
    URL_CONSTANTS.updateMassmodificationemployee,
    dto,
    onSuccess,
    onError
  );
};

const get_employee_assigned_policies_for_dependants = (
  onSucces: any,
  onError: any,
  empId: string
) => {
  GetAPI.call(
    URL_CONSTANTS.get_employee_assigned_policies(empId),
    onSucces,
    {},
    onError
  );
};

const dependent_policy_config_check = (
  onSuccess: any,
  onError: any,
  policyId: string,
  selectedRelationship: string,
  empId: string,
  user: string
) => {
  GetAPI.call(
    URL_CONSTANTS.dependent_policy_config_check(
      policyId,
      selectedRelationship,
      empId,
      user
    ),
    onSuccess,
    {},
    onError
  );
};

const get_dependent_assigned_policies = (
  onSuccess: any,
  onError: any,
  empId: string,
  depId: string
) => {
  GetAPI.call(
    URL_CONSTANTS.get_dependent_assigned_policies(empId, depId),
    onSuccess,
    {},
    onError
  );
};

const LOAD_FIELD_DATA_FOR_BULK_UPLOAD = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  user:string,
  key: string,
  code: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.member_field_data_for_bulk_upload(user)}${key}&code=${code}`,
    onSuccess,
    onError
  );
};

const get_endorsement_list = (
  onSucces: any,
  onError: any,
  empId: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_endorsement_list}${empId}`,
    onSucces,
    {},
    onError
  );
};
const get_claims_list = (
  onSucces: any,
  onError: any,
  empId: string
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_claims_list}${empId}`,
    onSucces,
    {},
    onError
  );
};

const get_status = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    URL_CONSTANTS.get_status_member,
    onSuccess,
    {},
    onError
  );
};
const dynamicFilter = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  user:string,
  clientId:string,
) => {
  GetAPI.call(
    URL_CONSTANTS.dynamicFilter(user,clientId),
    onSuccess,
    {},
    onError
  );
};
const getFiltervaluesByName = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  user:string,
  filter:string,
  clientId:string,
) => {
  GetAPI.call(
    URL_CONSTANTS.getFiltervaluesByName(user,filter,clientId),
    onSuccess,
    {},
    onError
  );
};

const get_status_enrolment = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    URL_CONSTANTS.get_status_enrolment,
    onSuccess,
    {},
    onError
  );
};

const get_status_nominee = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    URL_CONSTANTS.get_status_nominee,
    onSuccess,
    {},
    onError
  );
};

const assignPolicy = (
  dto: any,
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  userType:string,
  id:string,
  selectedGrade:string
) => {
  PatchAPI.call(
    URL_CONSTANTS.assignPolicy(userType,id,selectedGrade),
    dto,
    onSuccess,
    {},
    onError
  );
};


const get_employee_dependant_list = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  userType: string,
  url:string
) => {
  GetAPI.call(
    URL_CONSTANTS.get_employee_dependant_list(userType,url),
    onSuccess,
    {},
    onError
  );
};
const get_custom_dropDown = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  user:string,
  policyId:string,
) => {
  GetAPI.call(
    `${URL_CONSTANTS.get_custom_dropDown(user)}${policyId}`,
    onSuccess,
    {},
    onError
  );
};

const get_status_employement = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
) => {
  GetAPI.call(
    URL_CONSTANTS.get_status_employement,
    onSuccess,
    {},
    onError
  );
};

// get employe detail view information
const employee_detail_layout = (
  id: string,
  onSuccess: (data: any) => void,
  onError: (err: any) => void
) => {
  GetAPI.call(
    `${EMPLOYEE_URL_CONSTANTS.get_employee_detail_layout}${id}`,
    onSuccess,
    onError
  );
};

// 
const checkIsMailUsed = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  mail: string,
  userType: string,
  type: string,
) => {
  GetAPI.call(
    `${URL_CONSTANTS.checkIsMailUsed(mail, userType, type)}`,
    onSuccess,
    {},
    onError
  );
};

const checkIsRelationExist = (
  onSuccess: (data: any) => void,
  onError: (err: any) => void,
  attachedPrimaryEmployee: string,
  relation: string,
) => {
  GetAPI.call(
    `${URL_CONSTANTS.checkIsRelationExist}employeeId=${attachedPrimaryEmployee}&relation=${relation}`,
    onSuccess,
    onError
  );
};

export const EMPLOYEE_SERVICES = {
  get_insurer_list,
  create_employee,
  get_client_list,
  get_location_list,
  get_header_tag,
  get_table_data,
  delete_employee,
  employee_detail_view_layout,
  update_detail_global_employee,
  update_detail_view_individual_edit_employee,
  get_status_data,
  get_dep_designation_salary,
  update_status,
  get_dropdown_data,
  updateMassmodification,
  massModification,
  get_employee_assigned_policies_for_dependants,
  dependent_policy_config_check,
  get_dependent_assigned_policies,
  LOAD_FIELD_DATA_FOR_BULK_UPLOAD,
  get_endorsement_list,
  get_claims_list,
  get_status,
  get_status_employement,
  get_status_enrolment,
  get_status_nominee,
  employee_detail_layout,
  checkIsMailUsed,
  memberlistAccordingPolicy,
  checkIsRelationExist,
  get_employee_dependant_list,
  assignPolicy,
  dynamicFilter,
  getFiltervaluesByName,
  get_custom_dropDown,
};
